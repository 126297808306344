/* ----------------------------------------------------------------------------
element styles
---------------------------------------------------------------------------- */

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection,
::-moz-selection {
  color: var(--color-sheet);
  background-color: var(--color-text);
}

html {
  font-size: 10px;
  background-color: var(--color-sheet);
}

body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: var(--font-main);
  font-size: var(--type-base);
  font-variation-settings: "wdth" 180;
  color: var(--color-text);
  background-color: var(--color-neutral);
}

h1 {
  margin: max(var(--space-xl), 30vmin) 0 var(--space-xs) 0;
  font-size: var(--type-xxxl);
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: var(--leading-s);
  transition: font-weight 0.2s ease;
}

h2 {
  margin: var(--space-l) 0 var(--space-s) 0;
  font-size: var(--type-xxl);
  font-weight: 600;
  text-transform: uppercase;
  font-style: italic;
  line-height: var(--leading-m);
}

h3 {
  margin: var(--space-l) 0 var(--space-xs) 0;
  font-size: var(--type-l);
  font-weight: 900;
  line-height: var(--leading-m);
}

h4,
h5,
h6 {
  margin: var(--space-m) 0 0 0;
  font-size: var(--type-m);
  font-weight: 600;
  line-height: var(--leading-m);
}

p,
ul,
ol,
dl {
  margin: var(--space-m) 0;
  font-size: var(--type-m);
  line-height: var(--leading-l);
  font-weight: 350;
}

ul {
  padding: 0;
  list-style-position: outside;
  list-style-type: none;
}

ol {
  padding: 0;
  list-style-position: outside;
  list-style-type: decimal;
}

li {
  margin-left: 0;
  margin-bottom: var(--space-xs);
  position: relative;
}

li ul,
li ol {
  margin-left: 1.25em;
  margin-bottom: 0;
  font-size: var(--type-m);
}

b,
strong {
  font-weight: 600;
}

i,
em {
  font-style: italic;
}

small {
  font-size: var(--type-s);
}

a,
a:visited {
  color: var(--color-text);
  text-decoration-thickness: 0.1em;
  text-decoration-width: 0.1rem;
  transition: all 0.2s ease;
  transition-property: text-decoration-color, text-decoration-thickness, text-decoration-width;
}

a:hover {
  text-decoration-color: var(--color-accent);
  text-decoration-thickness: 0.2em;
  text-decoration-width: 0.2em;
}

img,
embed,
object,
video {
  display: block;
  max-width: 100%;
  height: auto;

  &:focus {
    outline: none;
  }
}

hr {
  width: 100%;
  margin: var(--space-xl) 0;
  border: 0;
  border-top: 0.1rem solid var(--color-text);
}

blockquote {
  position: relative;
  margin: var(--space-l) 0;
  padding: 0;
  quotes: "\201C""\201D""\2018""\2019";
  text-transform: uppercase;
  font-size: var(--type-m);
  letter-spacing: 0.1em;
  font-weight: 200;
  font-style: italic;
}
