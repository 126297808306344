/* ----------------------------------------------------------------------------
global site header
---------------------------------------------------------------------------- */

.header {
  width: 100%;
  text-align: center;
}

.header__spark {
  width: 18em;
  margin: 3em auto 0;
  position: relative;

  &::before {
    content: " ";
    display: block;
    height: 100%;
    position: absolute;
    top: -3em;
    left: 0;
    right: 0;
    margin: 0 0 0 0;
    background: url('/assets/wiring/leads.svg') center 6em / 15em auto no-repeat;
    pointer-events: none;
    transform: rotate(180deg);
  }

  & .spark__outer {
    fill: var(--color-sheet);
  }

  & .spark__inner {
    fill: var(--color-accent);
    animation: color-cycle-fill 0.3s step-start infinite;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }
}

.header__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 20000;
  margin: -4.5em 0 0;
  padding: 0 2em;
}

.header__logo {
  width: 15em;
  margin: 0;
  text-align: center;

  & svg {
    overflow: visible;
    width: 100%;
    height: auto;
  }

  & .bolt,
  & .letter {
    fill: var(--color-text);
    transition: fill 0.2s ease;
  }

  &:hover {
    & .bolt {
      /* animation: color-cycle-fill 0.3s step-start infinite; */
      fill: var(--color-accent);

      @media (prefers-reduced-motion) {
        animation: none;
        fill: var(--color-accent);
      }
    }
  }
}

.header__tagline {
  margin: 0;
  font-size: var(--type-s);
  font-weight: 400;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  line-height: 1;
}

.header__skip:hover,
.header__skip:active,
.header__skip:focus {
  clip: auto !important;
  top: 1rem !important;
  left: 1rem !important;
  width: auto !important;
  height: auto !important;
  z-index: 100000;
}
