/* ----------------------------------------------------------------------------
it's a button... that you click
---------------------------------------------------------------------------- */

:root {
  --button-background-color: var(--color-link);
  --button-text-color: var(--color-text);
  --button-hover-background-color: var(--color-link);
  --button-hover-text-color: var(--color-text);
}

.button {
  transition: font-weight 0.1s ease, clip-path 0.2s ease;
  display: inline-block;
  padding: 1em 1.5em;
  color: var(--button-text-color);
  font-family: var(--font-main);
  text-decoration: none;
  text-align: center;
  line-height: normal;
  -webkit-appearance: none;
  background-color: var(--button-background-color);
  border: 0;
  box-shadow: none;
  clip-path: polygon(5% 10%, 46% 1%, 46% 10%, 100% 0, 95% 90%, 31% 100%, 31% 90%, 0% 100%);

  &:visited {
    color: var(--button-text-color);
  }

  &:hover {
    color: var(--button-hover-text-color);
    cursor: pointer;
    text-decoration: none;
    font-weight: 800;
    background-color: var(--button-hover-background-color);
    clip-path: polygon(5% 10%, 66% 1%, 66% 10%, 100% 0, 95% 90%, 51% 100%, 51% 90%, 0% 100%);
  }
}

.button.--brand-turbo {
  --button-background-color: var(--color-brand-turbo);
}

.button.--brand-stimulus {
  --button-background-color: var(--color-brand-stimulus);
}

.button.--brand-strada {
  --button-background-color: var(--color-brand-strada);
  --button-text-color: var(--color-inversed-text);
}

.button.--brand-native {
  --button-background-color: var(--color-brand-native);
  --button-text-color: var(--color-inversed-text);
}
