/* ----------------------------------------------------------------------------
homepage components
---------------------------------------------------------------------------- */

.home-block {
  text-align: center;

  &.--spark::before {
    content: '';
    display: block;
    width: 12em;
    height: 12em;
    position: absolute;
    z-index: -1;
    left: calc(50% - 6em);
    top: 1.5em;
    background: url('/assets/wiring/spark.svg') center / contain no-repeat;
  }

  &.--spark:nth-child(2) {
    &::before {
      transform: rotate(-40deg);
    }
  }

  &.--spark:nth-child(3) {
    &::before {
      transform: rotate(60deg);
    }
  }

  &.--spark:nth-child(4) {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.home-block__logo {
  display: inline-block;
  height: 1.5em;
  width: auto;
  position: relative;
}
