/* ----------------------------------------------------------------------------
padding utilities
---------------------------------------------------------------------------- */

.pad {

  &.--hard { padding: var(--space-m); }
  &.--firm { padding: var(--space-l); }
  &.--soft { padding: var(--space-xl); }

  &.--top-hard { padding-top: var(--space-m); }
  &.--top-firm { padding-top: var(--space-l); }
  &.--top-soft { padding-top: var(--space-xl); }

  &.--bottom-hard { padding-bottom: var(--space-m); }
  &.--bottom-firm { padding-bottom: var(--space-l); }
  &.--bottom-soft { padding-bottom: var(--space-xl); }

  &.--left-hard { padding-left: var(--space-m); }
  &.--left-firm { padding-left: var(--space-l); }
  &.--left-soft { padding-left: var(--space-xl); }

  &.--right-hard { padding-right: var(--space-m); }
  &.--right-firm { padding-right: var(--space-l); }
  &.--right-soft { padding-right: var(--space-xl); }
}
