/* ----------------------------------------------------------------------------
the page scroll element created by has-scrolled.js
---------------------------------------------------------------------------- */

.scroll-sentinel {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
}
