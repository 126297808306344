.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  text-align: center;
}

.footer__headline {
  font-size: 75%;
  margin: 0 0 0.5em 0;
}

.footer__links {
  align-items: center;
  display: flex;
  gap: 0.9em;
  margin: 0;

  & li {
    display: flex;
    margin: 0;

    & a {
      display: flex;

      & img {
        display: block;
        height: 1.3em;
        width: auto;
      }
    }
  }
}

.footer__copyright {
  font-size: 75%;
  margin: 2em 0 3em 0;
}
