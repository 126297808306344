/* ----------------------------------------------------------------------------
custom media queries
---------------------------------------------------------------------------- */
@custom-media --viewport-small (max-width: 44.9em);
@custom-media --viewport-medium (min-width: 45em);
@custom-media --viewport-large (min-width: 90em);
@custom-media --viewport-retina (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi);

/* ----------------------------------------------------------------------------
custom properties
---------------------------------------------------------------------------- */
:root {
  /* colors */
  --color-white: #fff;
  --color-black: #000;
  --color-beige: #fbf7f0;
  --color-yellow: #ffe801;
  --color-teal: #15d1d8;
  --color-pink: #ef6baa;

  /* semantic colors */
  --color-text: var(--color-black);
  --color-inversed-text: var(--color-white);
  --color-sheet: var(--color-white);
  --color-neutral: var(--color-beige);
  --color-link: var(--color-yellow);
  --color-accent: var(--color-yellow);
  --color-positive: var(--color-teal);
  --color-negative: var(--color-pink);

  /* brand colors  */
  --color-brand-turbo: #5cd8e5;
  --color-brand-stimulus: #77e8b9;
  --color-brand-strada: #e76057;
  --color-brand-native: #c18bf4;

  /* spacing presets */
  --space-xs: 0.25em;
  --space-s: 0.5em;
  --space-m: 1em;
  --space-l: 1.5em;
  --space-xl: 3em;
  --space-xxl: 4em;
  --space-xxxl: 6em;

  /* font stacks */
  --font-main: 'Jost', 'Helvetica Neue', helvetica, 'Apple Color Emoji', arial, sans-serif;

  /* default type size */
  --type-base: max(1.8em, min(calc(1em + 0.9vw), 2.4em));

  /* type scales */
  --type-xxxs: 55%;
  --type-xxs: 65%;
  --type-xs: 75%;
  --type-s: 85%;
  --type-m: 100%;
  --type-l: 120%;
  --type-xl: 150%;
  --type-xxl: 200%;
  --type-xxxl: 300%;
  --type-xxxxl: 600%;

  /* line heights */
  --leading-s: 1.1;
  --leading-m: 1.4;
  --leading-l: 1.7;
  --leading-xl: 2;
}

@media (--viewport-medium) {
  :root {
    --type-xxxl: 390%;
  }
}
