/* ----------------------------------------------------------------------------
video component
---------------------------------------------------------------------------- */

.video {
  margin: 1.5em 0;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.05);
  border-radius: 2rem;
  overflow: hidden;
  background-color: var(--color-sheet);
}
